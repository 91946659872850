import * as Yup from "yup";
import { FormikConsumer } from "formik";
import { Col, Divider, message, Row } from "antd";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";

import { Account, Api } from "api";
import { Card } from "components/Card";
import { Icon } from "components/Icon";
import { Button } from "components/Button";
import { PaymentModal } from "containers/Payment";
import { Text, Title } from "components/Typography";
import type { PaymentResponse } from "containers/Payment";
import { useConfig, useCustomer, useLazyApiCall } from "state";
import { refreshCustomer } from "state/effects/refreshCustomer";
import { CheckboxField, Field, Form, TextField } from "components/Form";
import {
  getHistory,
  formatPrice,
  priceValidator,
  getCurrencySymbol,
  apiServices,
  withMaxDecimals,
} from "helpers";
import { PaymentCardRow } from "./components/PaymentCardRow";
import { PointBuckets } from "./components/PointBuckets";

export interface PointBucket {
  bucketId?: string;
  bucketName?: string;
  orderId?: number;
  price?: number;
  pointsAward?: number;
}

export const ManualReload: FC = () => {
  const [openNewPayment, setOpenNewPayment] = useState(false);
  const [lastPaymentAmount, setLastPaymentAmount] = useState("");
  const [pointBuckets, setPointBuckets] = useState([] as PointBucket[]);
  const { t } = useTranslation();
  const {
    params: { id: accountId },
  } = useRouteMatch<{ id: string }>();
  const customer = useCustomer();
  const accounts = (customer && customer.accounts) || [];
  // load Account -> TODO: use API
  const account = accounts.find((it) => it.accountId === accountId) as Account;
  const isPointsAccount = account.accountType === "POINTS_ACCOUNT";
  const { ok, error, triggered, dispatch, isLoading } = useLazyApiCall(
    apiServices.load(accountId),
    refreshCustomer
  );

  // fetch point buckets
  const pointBucketsApi = useLazyApiCall(apiServices.pointBuckets());

  useEffect(() => {
    pointBucketsApi.dispatch();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (pointBucketsApi.ok && pointBucketsApi.data) {
      setPointBuckets(pointBucketsApi.data as PointBucket[]);
    }
  }, [pointBucketsApi.ok, pointBucketsApi.data]);

  const [payments, setPayments] = useState<Array<Api["SavedPayment"] & { data?: PaymentResponse }>>(
    customer && customer.savedPayments && customer.savedPayments.length > 0
      ? customer.savedPayments
      : []
  );
  const maxLoad = account && account.maxAmount;
  const maxBalance = account && account.maxBalance;
  const balance = (account && account.balance) || 0;
  const maxValue = maxBalance === undefined ? undefined : maxBalance - balance;
  const config = useConfig();
  const isVE = config.connectorType === "VE";

  const handleSuccessCard = (cb: (id: string) => void) => (data: PaymentResponse) => {
    setOpenNewPayment(false);

    setPayments((s) => {
      const id = `newPayment-${s.length}`;

      cb(id);
      return [
        ...s.slice(0, 1), // getting first (previously saved) payment method and replacing the newly added one
        data.type === "PAY_CONEX"
          ? {
              creditCardExpiry: data.data.masked.expy,
              creditCardLast4: (data.data.masked.number || "").substr(-4),
              creditCardType: "unknown",
              data,
              id,
            }
          : {
              creditCardExpiry: "****",
              creditCardLast4: data.data.attributes.MaskedCardNumber.substr(-4),
              creditCardType: data.data.attributes.CardIssuer,
              data,
              id,
            },
      ];
    });
  };

  const schema = useMemo(
    () =>
      Yup.object()
        .shape(
          {
            amount: Yup.string()
              .when("pointsBucketId", {
                is: (val) => !!val,
                otherwise: Yup.string().required(t("VALIDATION_REQUIRED")),
                then: Yup.string().notRequired(),
              })
              .nullable()
              .test(priceValidator as never)
              // .required()
              .test(
                "maxLoadAmount",
                t("You cannot load more than {max} to this account", { max: formatPrice(maxLoad) }),
                (val: unknown) => {
                  return !maxLoad || !val || !Number(val) || Number(val) <= maxLoad;
                }
              )
              .test(
                "maxBalance",
                t(`Maximum Account Value for this Account is {maxAcct}, you can only load {max}.`, {
                  max: formatPrice(maxValue),
                  maxAcct: formatPrice(maxBalance),
                }),
                (val: unknown) => {
                  return (
                    !isVE ||
                    maxValue === undefined ||
                    !val ||
                    !Number(val) ||
                    Number(val) <= maxValue
                  );
                }
              )
              .default(null),
            payment: Yup.string()
              .nullable()
              .default(payments && payments.length ? payments[0].id : null),
            pointsBucketId: Yup.string()
              .when("amount", {
                is: (val) => !!val,
                otherwise: Yup.string().required(t("VALIDATION_REQUIRED")),
                then: Yup.string().notRequired(),
              })
              .nullable()
              .default(null),
            replace: Yup.boolean().default(false),
            terms: Yup.boolean()
              .when("payment", {
                is: (v) => v && v.startsWith("newPayment"),
                then: Yup.boolean()
                  .required()
                  .oneOf([true], t("TERMS_CONDITIONS_MUST_BE_ACCEPTED")),
              })

              .default(false),
          },
          [["amount", "pointsBucketId"]]
        )

        .defined(),
    [isVE, maxLoad, maxBalance, maxValue, t, payments]
  );

  type FormData = Yup.InferType<typeof schema>;

  const initialValues = useMemo(
    () =>
      schema.cast(
        account && account?.autoloadDetails?.subscriptionStatus
          ? {
              amount: account.autoloadDetails?.loadAmount,
              min: account.autoloadDetails?.thresholdAmount,
            }
          : {}
      ),
    [account, schema]
  );

  const handleSubmit = (data: FormData) => {
    setLastPaymentAmount(
      data.amount
        ? String(data.amount)
        : data.pointsBucketId
        ? String(
            pointBuckets.find((bucket) => bucket.bucketId === data.pointsBucketId)?.pointsAward ??
              "0"
          )
        : "0"
    );
    let body = {};

    if (data.payment?.startsWith("newPayment")) {
      const card = payments.find((i) => i.id === data.payment);
      if (!card || !card.data || !card.data?.data) {
        return;
      }

      if (card.data.type === "FREEDOM_PAY") {
        body = {
          payment: {
            nameOnCard: card.data.data.nameOnCard,
            paymentKey: card.data.data.paymentKey,
            sessionKey: card.data.data.sessionKey,
            type: card.data.type,
          },
          saveCard: data.replace,
          type: "NEW",
        } as Api["LoadAccountPostNewCard"];
      } else {
        body = {
          payment: {
            creditCardExpiry: card.creditCardExpiry,
            creditCardLast4: card.creditCardLast4,
            creditCardToken: card.data?.data.eToken,
            type: card?.data?.type,
          },
          saveCard: data.replace,
          type: "NEW",
        } as Api["LoadAccountPostNewCard"];
      }
    } else {
      body = {
        savedPaymentId: data.payment || "",
        type: "SAVED",
      } as Api["LoadAccountPostSavedCard"];
    }

    if (isPointsAccount) {
      // eslint-disable-next-line
      (body as any).pointsBucketId = data.pointsBucketId;
    } else {
      // eslint-disable-next-line
      (body as any).loadAmount = Number(data.amount);
    }

    dispatch({ body }, 1);
  };

  const lastTrig = useRef(triggered || 0);
  const lastTrigPoints = useRef(pointBucketsApi.triggered || 0);

  useEffect(() => {
    if (error && lastTrig.current < (triggered || 0)) {
      message.error(t("ACCOUNT_LOADING_FAILED"), 10);
    }
    lastTrig.current = triggered || 0;
  }, [triggered, error, t]);

  useEffect(() => {
    if (pointBucketsApi.error && lastTrigPoints.current < (pointBucketsApi.triggered || 0)) {
      message.error(t("SOMETHING_WENT_WRONG"));
    }
    lastTrigPoints.current = pointBucketsApi.triggered || 0;
  }, [pointBucketsApi.triggered, pointBucketsApi.error, t]);

  useEffect(() => {
    if (ok) {
      message.success(
        t(isPointsAccount ? "ACCOUNT_LOADED_POINTS" : "ACCOUNT_LOADED_WITH", {
          count: lastPaymentAmount || "0",
          // eslint-disable-next-line
        } as any),
        10
      );
      getHistory().pushWithTheme("/reload");
    }
    // eslint-disable-next-line
  }, [ok, t, lastPaymentAmount]);

  const {
    params: { theme },
  } = useRouteMatch<{ theme: string }>();

  const goBack = () => {
    getHistory().push(`/${theme}/reload`);
  };

  if (!account) {
    return null; // TODO: add error
  }

  const pointsLabel =
    config?.amsSite?.siteTheme?.labels?.["label.points.title.text"] ?? t("POINTS");

  return (
    <>
      <Form<FormData>
        id="passwordForm"
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={(data) => {
          handleSubmit(data);
        }}
      >
        <div className="d-flex align-items-center mb-4">
          <Button type="text" onClick={goBack}>
            <Icon name="chevron-left" />
          </Button>
          <Title className="mb-0" level={1}>
            {t("LOAD_NAME", { name: account?.accountName })}
          </Title>
        </div>

        {/* check if points account and show different ui */}
        {isPointsAccount ? (
          <div>
            <Card className="mb-4" padding="large">
              <Row className="mb-4">
                <Col md={12} xs={24}>
                  <div className="w-100 d-flex justify-content-between align-items-center">
                    <Text strong size="medium">
                      {`${t("CURRENT_BALANCE", { balance: balance })} ${pointsLabel}`}
                    </Text>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={12} xs={24}>
                  <Text strong size="normal">
                    <div>{t("LOAD_ACCOUNT")}:</div>
                    <div>{t("CHOOSE_YOUR_PLAN")}:</div>
                  </Text>
                </Col>
              </Row>

              <PointBuckets pointBuckets={pointBuckets} />
            </Card>
          </div>
        ) : (
          <>
            {/* Config form */}
            <Card className="mb-4" padding="large">
              <Row className="mb-4">
                <Col md={12} xs={24}>
                  <div className="w-100 d-flex justify-content-between align-items-center mb-2">
                    <Text strong size="medium">
                      {t("Current Balance: {balance}", { balance: formatPrice(balance) })}
                    </Text>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={12} xs={24}>
                  <Field
                    component={TextField}
                    label={t("LOAD_AMOUNT")}
                    name="amount"
                    prefix={getCurrencySymbol()}
                    transformValue={(val) => String(withMaxDecimals(val))}
                  />
                </Col>
              </Row>

              {!isVE && !!maxValue && (
                <Text>
                  {t("MAX_ALLOWED_BALANCE_MESSAGE", {
                    // TODO: think about custom ICU formatter {max, price}
                    dif: formatPrice(maxValue),
                    maxAmount: formatPrice(account.maxAmount),
                  })}
                </Text>
              )}
            </Card>
          </>
        )}

        {/* Payment Options */}
        <Title className="mb-3" level={5}>
          {t("PAYMENT_METHOD")}
        </Title>

        <Card className="mb-3" padding="none">
          {payments.map((it, k) => (
            <React.Fragment key={it.id + k}>
              <PaymentCardRow
                id={it.id}
                name="payment"
                number={it.creditCardLast4 || ""}
                type={it.creditCardType || ""}
              />
              <Divider className="m-0" />
            </React.Fragment>
          ))}

          <Row>
            <Col className="d-flex align-items-center py-3 px-4" md={24} xs={24}>
              <Button type="link" onClick={() => setOpenNewPayment(true)}>
                {payments && payments.length > 0
                  ? t("USE_NEW_PAYMENT_METHOD")
                  : t("ADD_NEW_PAYMENT_METHOD")}
              </Button>
            </Col>
          </Row>
        </Card>

        <Field
          className="mb-0"
          component={CheckboxField}
          label={
            <>
              {t("I_AGREE_WITH")}{" "}
              <a href={config.amsSite?.term} rel="noreferrer" target="_blank">
                {t("TERMS_AND_CONDITIONS")}
              </a>
            </>
          }
          name="terms"
        />

        {/* T&C confirmation */}
        <FormikConsumer>
          {({ values: { payment } }) =>
            payment && payment.startsWith("newPayment") ? (
              <>
                <Field
                  className="mb-0"
                  component={CheckboxField}
                  label={t("SAVE_NEW_PAYMENT_METHOD_REPLACE_DEFAULT")}
                  name="replace"
                />
              </>
            ) : null
          }
        </FormikConsumer>

        {/* Actions */}
        <FormikConsumer>
          {({ values, setFieldValue, errors }) => {
            const loadBtnDisabled =
              !values.terms || !values.payment || Object.keys(errors).length > 0;
            return (
              <>
                <Row className="mt-5" gutter={16}>
                  <Col lg={4} md={8} xs={12}>
                    <Button block disabled={isLoading} type="secondary" onClick={goBack}>
                      {t("CANCEL")}
                    </Button>
                  </Col>
                  <Col lg={4} md={8} xs={12}>
                    <Button
                      block
                      disabled={isLoading || loadBtnDisabled}
                      htmlType="submit"
                      type="primary"
                    >
                      {t("LOAD")}
                    </Button>
                  </Col>
                </Row>

                <PaymentModal
                  isOpen={openNewPayment}
                  onRequestClose={() => setOpenNewPayment(false)}
                  onSuccess={handleSuccessCard((id) => setFieldValue("payment", id))}
                />
              </>
            );
          }}
        </FormikConsumer>
      </Form>
    </>
  );
};
